export const allowlistAddresses = [

  ["0xa0eab382c09b38db4c21a0dbfa9282ff955acd52",1],
  ["0x635cab3d1df1c87e3c6534b85f24cc794d883698",1],
  ["0xee7dee83fe3209db0035fcf60f1edd930ec2f8c0",1],
  ["0x51c9631896950a6fd0b03a709614fb75d891b3fa",1],
  ["0x90f899d604522945ad09651cf0f396adf1850e60",1],
  ["0xa1a45a2b5fcdcdc590f5203d194e563ae6742e32",1],
  ["0x610d8db1a96ecc513b588fc5ca514e33512c91a8",1],
  ["0xf12b6f7a5a93dfa4de869f0fcbad0947459e8551",1],
  ["0x94faf72a341102c9d718046038510f13fd604662",1],
  ["0x756cf9df2d490c192243f047f808864c2369b825",1],
  ["0x0a11605280c54f62f4968dbd7078981006716355",1],
  ["0x89df776f886e4503516096ed8933c5c4e1ab12c7",1],
  ["0xd0bd462e4655d47445635eda0ce137c2acd976f6",1],
  ["0xbe0761cbc112be1a324b4ef7f44ecf11e37812e3",1],
  ["0x8878417c2766fe57fa265d73e8ce42b2bc4af5ac",1],
  ["0x1f084ea143597e5380d65e6e3e94b68e5801eccf",1],
  ["0xa740a0c25036dd72ce0d1fe31c8d003f0da33809",1],
  ["0xc1124f513e1ef19b0ae344808a188c87fb3fc0f9",1],
  ["0xd1b7b664ae46f07229519c8d21aaaf60cc614c1b",1],
  ["0xef716a2d14d64b6f4a770a07c229b42eae6c67af",1],
  ["0x24c585a904f72e972e10dab907df6ee3b6ea37c9",1],
  ["0x60023f4b98d12c5f921e92f79108515d44f9abea",1],
  ["0x1b365df8c62b0cec1316d9ea918898c4edcd1e6c",1],
  ["0x2b45f2338d64c1bf6d90f4d937e2f6923d93a3a4",1],
  ["0x6108c61394307e87a42d03385c3a319c64680d28",1],
  ["0xfd30a5b4c7c3abdc1c82cd4d61818f5a0a294631",1],
  ["0xc3ebdea05821dfa77a7b512aa2dc115162efaf16",1],
  ["0x529d10e2dd687841ba30e3ddfb220a9b4ad032cb",1],
  ["0x071918fea48b3bda1e4aaaa7b24d4ab742e49305",1],
  ["0x2f617fa1c80bdeb85fc01c8dddd2fefd5a13a960",1],
  ["0x86eb8be678274ba4c47af17fa8511c3f9c631bbc",1],
  ["0x871ba959a4628019055d52a11743d0f7edc40808",1],
  ["0x89fc135b9edd480d4945afb36f67b609e73e1b90",1],
  ["0xc3b864a42b794b972ed82f26c9d93b50f28279d6",1],
  ["0x62fe1a7be2bd860c24bb3efc3f81395b59cb4b31",1],
  ["0x42b9fa98953b51e2c4a96722fb67460ea468d814",1],
  ["0x5f6e3cc9d229c42004e9a8c49e185da28aa6e482",1],
  ["0xb577575a51927c83c9a30087dac9329b5d1c4571",1],
  ["0x01b27ec780c534ba0fab15509354c3798321273c",1],
  ["0x6c69f2af5bc8473ee4f5d281cd8403e45c114553",1],
  ["0x5a47de3c14f027796b804d8d26d94b1e41546cc4",1],
  ["0x24aae0a135985dc8ae6b8bac64051af645c2b3f9",1],
  ["0xeab18af7bb056f002791c68e79ab382a37c2d446",1],
  ["0x89fc6d9f9fe66ce7b5c1a8d17542b96f98925a81",1],
  ["0xadd71e9d1e0cc9a89fc14f1c1302fa6a4d4def44",1],
  ["0xfd487911196048348e13a5933e25a8dfa1c64995",1],
  ["0xeec66737e685278e6a2c495a6fe4679a14c68f35",1],
  ["0x8c7e0f91045bd4c9d2f430d53c0769f5dff725e4",1],
  ["0xfe0ae9d5bdf0077ce7c54ad2cef4d05049434973",1],
  ["0x667363aa2cf82c805664a998ea09a07a39820804",1],
  ["0x7a074a149869414f1efceb8c9447cba167174813",1],
  ["0xa8aa94ec42a3fb3b1580387369ead46fad01bf2b",1],
  ["0x82e1e9666edac4eb98850ce2ef2cf9bfedf2183d",1],
  ["0x53596b3f75c8c529115b1fa9eb66f0fdb3c12d57",1],
  ["0xf308cc36bd85f135b5e65c8b8ede781b1cbed7bc",1],
  ["0x315b1490a0318fc4c443348783daad91651e5e85",1],

]